import { useState, useRef } from 'react';
import {Form, Container, Grid, Button, Label, List} from "semantic-ui-react";

const WordCount = () => {
  const [occurrences, setOccurrences] = useState(3);
  const [minLength, setMinLength] = useState(3);
  const [maxLength, setMaxLength] = useState(99);
  const [results, setResults] = useState([]);
  const [deny, setDeny] = useState([]);
  const ref = {
    occurrences: useRef(null),
    minLength: useRef(null),
    maxLength: useRef(null),
    text: useRef(null),
  };

  // useEffect(() => {
  //   calculate()
  // });

  const calculate = () => {
    const data = {};
    for(const [key, value] of Object.entries(ref)){
      data[key] = value.current.value;
    }
    const words = Object.values(
        data.text.split(/\W/)
          .filter(w => !deny.includes(w))
          .filter(w => w.length > minLength && w.length < maxLength)
          .map(w => w.toLowerCase())
          .reduce((acc, w) => {
            if(!acc[w]){
              acc[w] = {
                word: w,
                occurrences: 0,
              };
            }
            acc[w].occurrences++;
            return acc
          }, {})
      )
      .filter(w => w.occurrences > occurrences)
      .sort((w1, w2) => w2.occurrences - w1.occurrences)
    ;

    setResults(words);
  }

  const addDenyWord = word => {
    setDeny([...deny, word].filter((v, i, a) => a.indexOf(v) === i));
  }

  const renderResult = res => (
    <List.Item key={res.word}>
      <Label color='blue' horizontal>
        {res.occurrences}
      </Label>
      {res.word}
      <Button onClick={() => addDenyWord(res.word)} size="mini" color="red" floated='right'>Deny</Button>
    </List.Item>
  );

  return (
    <Container>
      <Form>
        <Grid columns={2}>
          <Grid.Row textAlign='center' stretched>
            <Grid.Column width={14}>
              <label>Text for processing</label>
              <textarea
                ref={ref.text}
                placeholder="Text for processing"
                style={{
                  display: 'block',
                  width: '100%',
                  height: '5em',
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <label>Deny Words</label>
              <textarea
                value={deny.join('\n')}
                placeholder="Deny Words"
                style={{
                  display: 'block',
                  width: '100%',
                  height: '5em',
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3}>
          <Grid.Row textAlign='center' stretched>
            <Grid.Column>
              <Form.Group inline>
                <label>Occurrences</label>
                <input
                  onChange={() => setOccurrences(parseInt(ref.occurrences.current.value) || 0)}
                  ref={ref.occurrences}
                  value={occurrences}
                  type="text"
                  placeholder="Occurrences"
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group inline>
                <label>Min Word Length</label>
                <input
                  onChange={() => setMinLength(parseInt(ref.minLength.current.value) || 0)}
                  ref={ref.minLength}
                  value={minLength}
                  type="text"
                  placeholder="Min Word Length"
                />
              </Form.Group>
            </Grid.Column>
            <Grid.Column>
              <Form.Group inline>
                <label>Max Word Legth</label>
                <input
                  onChange={() => setMaxLength(parseInt(ref.maxLength.current.value) || 0)}
                  ref={ref.maxLength}
                  value={maxLength}
                  type="text"
                  placeholder="Max Word Length"
                />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button
          primary
          floated='right'
          onClick={() => calculate()}
        >Calculate</Button>
      </Form>
      <List
        divided
        selection
        style={{marginTop: '4em'}}
      >
        {results.map(renderResult)}
      </List>
    </Container>
  );
}

export default WordCount;