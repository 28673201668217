import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import 'fomantic-ui-css/semantic.css';
import Header from "./components/Header";
import WordCount from "./components/WordCount";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route path='/' exact>home</Route>
        <Route path='/word-counter'>
          <WordCount />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
