import logo from '../logo.svg';
import {Menu} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';

const Header = props => {
  return (
    <Menu>
      <Menu.Item header link active={props.location.pathname === '/'}>
        <Link to='/'>
          <img src={logo} alt="Rank Tools" width="30"/>
        </Link>
      </Menu.Item>
      <Menu.Item link active={props.location.pathname === '/word-counter'}>
        <Link to='/word-counter'>Word Count</Link>
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(Header);